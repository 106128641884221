import React from "react";
import Container from "../../../components/chat-channel/Container";
import Layout from "../../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMedia } from "use-media";
import { CookiesPoup } from "../../homepage";

const goto_logo = require("../../../assets/images/testimonials/goto_logo_mob.svg");
const heart = require("../../../assets/images/testimonials/heart_icon.svg");
const about_goto = require("../../../assets/images/testimonials/about_goto.png");
const the_challenge = require("../../../assets/images/testimonials/challenge_img.png");
const results = require("../../../assets/images/testimonials/results_img.png");
const results_mob = require("../../../assets/images/testimonials/results_img_mob.png");
const video = require("../../../assets/images/testimonials/goto_case_video_img.png");
const video_mob = require("../../../assets/images/testimonials/goto_case_video_img_mob.png");
const solution = require("../../../assets/images/testimonials/solution_img.png");
const tick_case = require("../../../assets/images/testimonials/tick.svg");
const goto_manager = require("../../../assets/images/testimonials/gabriel_pic.png");
const goto_manager_mobile = require("../../../assets/images/testimonials/goto_manager_2.png");
const quote = require("../../../assets/images/testimonials/quote_icon.svg");
const goto_white = require("../../../assets/images/testimonials/goto_logo_white.svg");
const arrow_open = require("../../../assets/images/testimonials/arrow_open.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close.svg");
const OgImage = require("../../../assets/img/home_page_assist/home_header_assist.png");

export default function GoToCase() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Workativ Assistant Case Study - GoTo Customer Case Study"
        description="Learn how Workativ helped the GoTo team to auto-resolve repetitive IT queries, issues, requests, and improve their employee experience."
        keywords={["workativ"]}
        ogTitle="Workativ Assistant Case Study - GoTo Customer Case Study"
        ogDescription="Learn how Workativ helped the GoTo team to auto-resolve repetitive IT queries, issues, requests, and improve their employee experience."
        ogImage={OgImage}
      />
      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          {isMobile ? <GoToCaseTopMobile /> : <GoToCaseTop />}
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          <RequestForm isFooterForm={true} />
          {/* {isSmall ? null : <OnScrollPopup />} */}
          {/* <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

function GoToCaseTop() {
  return (
    <section className="goto_case_section_desk w-100 float-left">
      <div className="container">
        <div className="goto_case_grid_container">
          <div className="goto_case_left_top">
            <h5 className="font-section-small-text">
              <u>Customer Spotlight</u>
            </h5>
            <h1 className="font-section-normal-text mb-3">
              GoTo adds Gen AI based self-service automation for employee
              support{" "}
              <span className="ml-2">
                <img src={heart} className="heart_image_goto_case"></img>
              </span>
            </h1>
            <h2 className="font-section-sub-header-bold-goto-case line-height-2">
              {" "}
              Learn how GoTo replaced its own chatbot platform BOLD360 with
              Workativ’s 4-in-1 bot platform and automated 80% of employee IT
              support.
            </h2>
            <div className="cards_goto_case">
              <div className="card_one_case d-flex">
                <h2 className="font-section-sub-header-bold mb-2">80%</h2>
                <p className="font-section-small-text-medium-case pb-0 mb-0 text-align-center">
                  AUTO-RESOLVED USING CHATBOT{" "}
                </p>
              </div>
              <div className="card_two_case d-flex">
                <h2 className="font-section-sub-header-bold mb-2">50+</h2>
                <p className="font-section-small-text-medium-case pb-0 mb-0 text-align-center">
                  APPLICATION COVERED
                </p>
              </div>
              <div className="card_three_case d-flex">
                <h2 className="font-section-sub-header-bold mb-2">24/7</h2>
                <p className="font-section-small-text-medium-case pb-0 mb-0 text-align-center">
                  AVAILABLITY ON SLACK
                </p>
              </div>
            </div>
          </div>
          <div className=" goto_case_right_top">
            <div className="goto_case_right_card">
              <img src={goto_logo} className="w-25 mb-4"></img>
              <h4 className="font-name-smaller-text-medium">COMPANY</h4>
              <p className="font-section-small-text">
                <span className="font-section-small-text-case-medium right_span_goto_case">
                  GoTo
                </span>{" "}
                is the #1 Software as a Service (SaaS) brand worldwide.
              </p>

              <h4 className="font-name-smaller-text-medium">HEADQUARTERS</h4>
              <p className="font-section-small-text">
                Located in Boston, Massachusetts.
              </p>

              <h4 className="font-name-smaller-text-medium">EMPLOYEES</h4>
              <p className="font-section-small-text">
                4,000+ global employees.
              </p>

              <h4 className="font-name-smaller-text-medium">INDUSTRY</h4>
              <p className="font-section-small-text pb-0 mb-0">
                Software/SaaS Industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ContentContainer() {
  return (
    <section className="w-100 float-left margin-bottom-40">
      <div className="container">
        <div className="grid_container_goto_second">
          <div className="grid-left-container-goto">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small mb-4">
                Table of Contents
              </h2>
              <div className="redirection-flex-container">
                <AnchorLink offset={180} href="#section1">
                  Introduction
                </AnchorLink>
                <AnchorLink offset={180} href="#section2">
                  About GoTo
                </AnchorLink>
                <AnchorLink offset={180} href="#section3">
                  The Challenge
                </AnchorLink>
                <AnchorLink offset={180} href="#section4">
                  Solution
                </AnchorLink>
                <AnchorLink offset={180} href="#section5">
                  Results
                </AnchorLink>
                <AnchorLink offset={180} href="#section6">
                  Why GoTo Choose Workativ
                </AnchorLink>
              </div>
              <div className="case_study_second_card">
                <p className="font-section-normal-text-testimonials line-height-2 text-align-center mb-0">
                  Learn more about GoTo’s success by speaking with our bot
                  expert.
                </p>
                <button
                  className="goto_case_book_demo_button"
                  onClick={() => {
                    window.location.href = "/conversational-ai-platform/demo/";
                  }}
                >
                  {" "}
                  <span className="font-section-small-text-medium">
                    Book demo here
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div id="section1">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                Introduction
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                IT service desk managers are the unsung heroes of your
                organization. An IT Service Desk team is responsible for
                ensuring that the IT support services are provided promptly,
                that the correct level of service is provided, and that employee
                IT issues & requests are resolved as soon as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Given that, an average day can be anything but normal for an IT
                Service Desk Manager.  From making sure the service desk team is
                delivering support services to the employees on time to ensuring
                the team are adequately skilled and staffed, all way to managing
                escalations. It could be a busy day.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                And more often, they also need to get on escalation calls with
                vendors and employees to resolve their queries during peak
                times, making it challenging to an already demanding role.  As
                you can imagine, it’s never a dull day.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As customers adopt new tech and new ways of doing things,
                employees do not want to be left behind. CIOs are acknowledging
                the changing needs of employees and business, and tasking IT
                Service Desk Managers to transform how business can support
                employees anywhere, anytime using modern approach built on
                foundation of self-service automation.  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Gabriel Grecco - Global IT Service Desk Manager at GoTo, and his
                team was in a similar situation; increasing calls and ticket
                volumes as employee size grew, improve employee experience, and
                modernize service desk model using creative use of emerging
                technology such chatbots and app workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-5">
                In this case study, we will discuss how GoTo is using Workativ
                chatbot platform to set up an automated process to auto-resolve
                their employees IT queries and requests.   
              </p>
            </div>
            <div id="section2">
              <h2 className="font-section-sub-header-small">
                About{" "}
                <span className="ml-2">
                  <img src={goto_logo} className="content_goto_logo">
                    {" "}
                  </img>
                </span>
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                <a href="https://www.goto.com/" target="_blank">
                  GoTo
                </a>{" "}
                is a provider of  software as a service (SaaS) and  cloud-based
                 remote work tools for collaboration and  IT management
                products, built for small and midsized business IT departments
                but powerful enough for the enterprise.
              </p>
              <img src={about_goto} className="mb-2"></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                It was founded in 2003 and rebranded from LogMeIn to GoTo on
                February 2022. They are in the city of Boston.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They are known for their products: GoTo connect, GoTo resolve,
                GoTo meetings, GoTo contact center, GoTo webinar, and GoTo
                training.   
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-5">
                Their products enable businesses to run IT operations more
                efficiently. 
              </p>
            </div>
            <div id="section3">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                The Challenge
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over the past few years, GoTo has grown so much and have over
                3500+ employees in their company. They wanted to provide a best
                possible experience for their employees. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GoTo has more than 50+ applications in their environment, like
                Adobe, VMware, SolarWinds ITSM, Office 365, SharePoint,
                NetSuite, Monday.com, Slack and other applications.  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The IT support team struggled to keep up with the growing number
                of requests from their global employees. The problem was that
                employees were left waiting for long hours when IT support
                agents were unavailable.  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The IT agents had to work on repetitive/similar requests from
                employees  which was time consuming and affecting the resolution
                time for other employees. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They wanted to handle employees IT queries in a more efficient
                way.    
              </p>
              <img src={the_challenge} className="mb-4"></img>
              <p className="font-section-normal-text-testimonials line-height-18 mt-3">
                They  also created the knowledge base by adding  FAQ pages for
                more than 100 repetitive queries, but it was spread across
                multiple platforms such as SharePoint and SolarWinds.   
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, it was not helping them improve their response and
                resolution time. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To achieve this, they had their own chatbot product called BOLD
                360, which was recently acquired by Genesys, a call center
                software company. So, Gabriel set out to find something better!
                 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While having a conversation with Gabriel, he mentioned that the
                company wanted to improve their employees’ experience by
                providing them with 24/7 support. They wanted to automate as
                many repetitive tasks as possible so that their team could focus
                on high-value activities.   
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-5">
                In a nutshell, they were looking for an efficient and
                cost-effective way to scale their IT support.
              </p>
            </div>
            <div id="section4">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                Solution
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                GoTo knew there was something better out there for them, so they
                reached out to Workativ for help (and 4 other chatbot vendors)  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GoTo needed an automated solution that would help them manage
                all the repetitive tasks like installing software, resetting
                passwords, unlocking accounts, and distributing lists, among
                other 50+ applications.  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Workativ wanted to improve this situation by making sure that
                chatbot available at any given time when an employee needs help
                with their IT-related issues.     
              </p>
              <img src={solution} className="mb-4 solution_img_case_goto"></img>
              <p className="font-section-normal-text-testimonials mt-3 line-height-18">
                We also wanted to make sure that the IT service desk team
                doesn’t receive repetitive questions from other employees who
                may be asking them repetitive questions —for example: “What was
                your password?” or “Can you unlock my account?” 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ team worked with them to identify their problem and
                articulated their pain points into use cases that the chatbot
                could solve. Also did a POC  for 3-4 weeks by building a chatbot
                with few key usecase and connecting with their system to test
                the bot capabilities.   
              </p>
              <div className="solution_case_list_container">
                <h3 className="font-text-medium-goto-solution mb-2 text-align-center">
                  Key automation usecase for GoTo chatbot include
                </h3>
                <hr className="underline_solution_heading mb-4 mt-1"></hr>
                <div className="solution_list_flex_container">
                  <div className="solution_list_left_flex">
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Reset password
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Unlock account
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Install printer
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Install software
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Disable MFA
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Create distribution list
                    </h4>
                  </div>
                  <div className="solution_list_right_flex">
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Manage distribution list
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Search for information or help article from Knowledge Base
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Update user phone number
                    </h4>
                    <h4 className="font-section-normal-text-testimonials solutions_points_goto_case line-height-18">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Create support tickets and get the ticket status updates
                    </h4>
                  </div>
                </div>
              </div>
              <p className="font-section-normal-text-testimonials line-height-18 mt-4 mb-5">
                The chatbot was  designed for users who are not tech-savvy or do
                not want to spend time on calls or emails when they have an
                issue. The chatbot can help employees to solve their problems
                instantly and easily without having to wait for hours. 
              </p>
            </div>
            <div id="section5">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                Results
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                They say numbers don’t lie. So we captured some key metrics that
                made the entire GoTo and Workativ team proud of what we have
                accomplished so far and excited about some of the new
                capabilities that has got GoTo excited in making Workativ their
                all-in-one support automation platform in using bots, to
                connecting knowledge AI, and much more.
              </p>

              <img src={results} className="mb-4"></img>
            </div>
            <div id="section6">
              <h2 className="font-section-sub-header-small mb-4">
                Why{" "}
                <span className="ml-1 mr-2">
                  <img src={goto_logo} className="content_goto_logo">
                    {" "}
                  </img>
                </span>
                chose <span style={{ color: "#2468F6" }}>Workativ</span>
              </h2>

              <div className="why_goto_case_section">
                <div className="why_goto_case_left">
                  <img src={quote} className="goto_quote_icon_case"></img>
                  <img src={goto_manager} className="goto_manager_case"></img>
                </div>
                <div className="why_goto_case_right">
                  <p className="font-section-normal-text-testimonials line-height-18">
                    After researching 5 different chatbot solutions, we decided
                    to go ahead with Workativ, for its’ easy integration with
                    systems that we use internally at GoTo. Workativ platform’s
                    advanced chatbot, app workflows, and deep Slack integration,
                    is a perfect fit for employee support automation.
                  </p>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    All the feedback that we passed to the team, was directly
                    turned into new functionalities in a matter of days! And we
                    felt supported throughout the entire onboarding process.
                  </p>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    The number of automation possibilities is immense! We can
                    empower our employees to self-service on tasks that were
                    currently handled by IT techs.
                  </p>
                  <div className="why_goto_bottom_destination">
                    <div>
                      <h2 className="font-section-sub-header-small-bold">
                        Gabriel Grecco
                      </h2>
                      <h4 className="font-section-normal-text-medium">
                        Global Manager - IT Service Desk
                      </h4>
                    </div>
                    <img src={goto_white}></img>
                  </div>
                </div>
              </div>

              <h3
                className="font-section-normal-text-medium"
                style={{ color: "#193A5D" }}
              >
                Here’s why workativ stood out from other chatbot platforms
              </h3>
              <ol className="why_goto_order_list">
                <li className="font-section-normal-text-testimonials">
                  <span>1</span>
                  Advanced chatbot platform with AI and App workflow automation
                  capabilities.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span>2</span>
                  Ability to connect with multiple applications instantly. 
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span>3</span>
                  Ability to deploy the chatbot in multiple channels like
                  self-help portal and Slack.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span>4</span>
                  No-Code platform (no need to depend on developers to build the
                  chatbot).
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span>5</span>
                  Transparent pricing.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span>6</span>
                  Ability to do a POC in weeks and test the chatbot before
                  making the decision.  
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span>7</span>
                  Top-notch onboarding and customer success team.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <h2 className="font-section-normal-text-case-study last_text_case_goto mt-4">
          Learn more about GoTo’s success by speaking with our bot expert. Book
          demo <a href="/conversational-ai-platform/demo/">here.</a>
        </h2>
      </div>
    </section>
  );
}

function GoToCaseTopMobile() {
  return (
    <section className="goto_case_section_desk w-100 float-left">
      <div className="container">
        <div className="goto_case_grid_container">
          <div className="goto_case_left_top">
            <h5 className="font-section-small-text mb-3">
              <u>Customer Spotlight</u>
            </h5>
            <h1 className="font-section-normal-text mb-3">
              GoTo adds Gen AI based self-service automation for employee
              support{" "}
              <span className="ml-2">
                <img src={heart} className="heart_image_goto_case"></img>
              </span>
            </h1>
            <h2 className="font-section-sub-header-bold-goto-case line-height-18 mb-4">
              {" "}
              Learn how GoTo replaced its own chatbot platform BOLD360 with
              Workativ’s 4-in-1 bot platform and automated 80% of employee IT
              support.
            </h2>
            <div className="cards_goto_case">
              <div className="card_one_case d-flex">
                <h2 className="font-section-sub-header-bold mb-1">80%</h2>
                <p className="font-section-small-text-medium-case pb-0 mb-0 text-align-center">
                  AUTO-RESOLVED USING CHATBOT{" "}
                </p>
              </div>
              <div className="card_two_case d-flex">
                <h2 className="font-section-sub-header-bold mb-1">50+</h2>
                <p className="font-section-small-text-medium-case pb-0 mb-0 text-align-center">
                  APPLICATION COVERED
                </p>
              </div>
              <div className="card_three_case d-flex">
                <h2 className="font-section-sub-header-bold mb-1">24/7</h2>
                <p className="font-section-small-text-medium-case pb-0 mb-0 text-align-center">
                  AVAILABLITY ON SLACK
                </p>
              </div>
            </div>
          </div>
          <div className=" goto_case_right_top">
            <div className="goto_case_right_card">
              <img
                src={goto_logo}
                className="right_card_case_logo_mobile mb-4"
              ></img>
              <h4 className="font-name-smaller-text-medium">COMPANY</h4>
              <p className="font-section-small-text">
                <span className="font-section-small-text-case-medium right_span_goto_case">
                  GoTo
                </span>{" "}
                is the #1 Software as a Service (SaaS) brand worldwide.
              </p>

              <h4 className="font-name-smaller-text-medium">HEADQUARTERS</h4>
              <p className="font-section-small-text">
                Located in Boston, Massachusetts.
              </p>

              <h4 className="font-name-smaller-text-medium">EMPLOYEES</h4>
              <p className="font-section-small-text">
                4,000+ global employees.
              </p>

              <h4 className="font-name-smaller-text-medium">INDUSTRY</h4>
              <p className="font-section-small-text pb-0 mb-0">
                Software/SaaS Industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function ContentContainerMobile() {
  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3">
      <div className="container">
        <div className="grid_container_goto_second">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink offset={180} href="#section1">
                    Introduction
                  </AnchorLink>
                  <AnchorLink offset={180} href="#section2">
                    About GoTo
                  </AnchorLink>
                  <AnchorLink offset={180} href="#section3">
                    The Challenge
                  </AnchorLink>
                  <AnchorLink offset={180} href="#section4">
                    Solution
                  </AnchorLink>
                  <AnchorLink offset={180} href="#section5">
                    Results
                  </AnchorLink>
                  <AnchorLink offset={180} href="#section6">
                    Why GoTo Choose Workativ
                  </AnchorLink>
                </div>
                <div className="case_study_second_card mt-4">
                  <p className="font-section-normal-text-case-study line-height-18 text-align-center mb-0">
                    Learn more about GoTo’s success by speaking with our bot
                    expert.
                  </p>
                  <button
                    className="goto_case_book_demo_button"
                    onClick={() => {
                      window.location.href =
                        "/conversational-ai-platform/demo/";
                    }}
                  >
                    {" "}
                    <span className="font-section-normal-text-medium">
                      Book demo here
                    </span>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div id="section1">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                Introduction
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                IT service desk managers are the unsung heroes of your
                organization. An IT Service Desk team is responsible for
                ensuring that the IT support services are provided promptly,
                that the correct level of service is provided, and that employee
                IT issues & requests are resolved as soon as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Given that, an average day can be anything but normal for an IT
                Service Desk Manager.  From making sure the service desk team is
                delivering support services to the employees on time to ensuring
                the team are adequately skilled and staffed, all way to managing
                escalations. It could be a busy day.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                And more often, they also need to get on escalation calls with
                vendors and employees to resolve their queries during peak
                times, making it challenging to an already demanding role.  As
                you can imagine, it’s never a dull day.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As customers adopt new tech and new ways of doing things,
                employees do not want to be left behind. CIOs are acknowledging
                the changing needs of employees and business, and tasking IT
                Service Desk Managers to transform how business can support
                employees anywhere, anytime using modern approach built on
                foundation of self-service automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Gabriel Grecco - Global IT Service Desk Manager at GoTo, and his
                team was in a similar situation; increasing calls and ticket
                volumes as employee size grew, improve employee experience, and
                modernize service desk model using creative use of emerging
                technology such chatbots and app workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this case study, we will discuss how GoTo is using Workativ
                chatbot platform to set up an automated process to auto-resolve
                their employees IT queries and requests.   
              </p>
            </div>
            <div id="section2">
              <h2 className="font-section-sub-header-small">
                About{" "}
                <span className="ml-1">
                  <img src={goto_logo} className="content_goto_logo">
                    {" "}
                  </img>
                </span>
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                <a href="https://www.goto.com/" target="_blank">
                  GoTo
                </a>{" "}
                is a provider of  software as a service (SaaS) and  cloud-based
                 remote work tools for collaboration and  IT management
                products, built for small and midsized business IT departments
                but powerful enough for the enterprise.
              </p>
              <img src={about_goto} className="mb-2"></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                It was founded in 2003 and rebranded from LogMeIn to GoTo on
                February 2022. They are in the city of Boston.  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They are known for their products: GoTo connect, GoTo resolve,
                GoTo meetings, GoTo contact center, GoTo webinar, and GoTo
                training.   
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Their products enable businesses to run IT operations more
                efficiently. 
              </p>
            </div>
            <div id="section3">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                The Challenge
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Over the past few years, GoTo has grown so much and have over
                3500+ employees in their company. They wanted to provide a best
                possible experience for their employees.    
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GoTo has more than 50+ applications in their environment, like
                Adobe, VMware, SolarWinds ITSM, Office 365, SharePoint,
                NetSuite, Monday.com, Slack and other applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The IT support team struggled to keep up with the growing number
                of requests from their global employees. The problem was that
                employees were left waiting for long hours when IT support
                agents were unavailable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The IT agents had to work on repetitive/similar requests from
                employees  which was time consuming and affecting the resolution
                time for other employees. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They wanted to handle employees IT queries in a more efficient
                way.         
              </p>
              <img src={the_challenge} className="mb-4"></img>
              <p className="font-section-normal-text-testimonials line-height-18 mt-1">
                They  also created the knowledge base by adding  FAQ pages for
                more than 100 repetitive queries, but it was spread across
                multiple platforms such as SharePoint and SolarWinds.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, it was not helping them improve their response and
                resolution time. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To achieve this, they had their own chatbot product called BOLD
                360, which was recently acquired by Genesys, a call center
                software company. So, Gabriel set out to find something
                better!  
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While having a conversation with Gabriel, he mentioned that the
                company wanted to improve their employees’ experience by
                providing them with 24/7 support. They wanted to automate as
                many repetitive tasks as possible so that their team could focus
                on high-value activities.   
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a nutshell, they were looking for an efficient and
                cost-effective way to scale their IT support.
              </p>
            </div>
            <div id="section4">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                Solution
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                GoTo knew there was something better out there for them, so they
                reached out to Workativ for help (and 4 other chatbot vendors)
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GoTo needed an automated solution that would help them manage
                all the repetitive tasks like installing software, resetting
                passwords, unlocking accounts, and distributing lists, among
                other 50+ applications. 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-1">
                Workativ wanted to improve this situation by making sure that
                chatbot available at any given time when an employee needs help
                with their IT-related issues.       
              </p>
              <img src={solution} className="mb-4 solution_img_case_goto"></img>
              <p className="font-section-normal-text-testimonials mt-3 line-height-18">
                We also wanted to make sure that the IT service desk team
                doesn’t receive repetitive questions from other employees who
                may be asking them repetitive questions —for example: “What was
                your password?” or “Can you unlock my account?” 
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We also wanted to make sure that the IT service desk team
                doesn’t receive repetitive questions from other employees who
                may be asking them repetitive questions —for example: “What was
                your password?” or “Can you unlock my account?”   
              </p>
              <div className="solution_case_list_container">
                <h3 className="font-section-normal-text-medium">
                  Key automation usecase for GoTo chatbot include
                </h3>
                <div className="solution_list_flex_container">
                  <div className="solution_list_left_flex">
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Reset password
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Unlock account
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Install printer
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Install software
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Disable MFA
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Create distribution list
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Manage distribution list
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Search for information or help article from Knowledge Base
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Update user phone number
                    </h4>
                    <h4 className="font-section-normal-text-testimonials line-height-18 solutions_points_goto_case">
                      <span className="mr-2">
                        <img src={tick_case}></img>
                      </span>
                      Create support tickets and get the ticket status updates
                    </h4>
                  </div>
                </div>
              </div>
              <p className="font-section-normal-text-testimonials line-height-18 mt-4">
                The chatbot was  designed for users who are not tech-savvy or do
                not want to spend time on calls or emails when they have an
                issue. The chatbot can help employees to solve their problems
                instantly and easily without having to wait for hours. 
              </p>
            </div>
            <div id="section5">
              <h2 className="font-section-sub-header-small heading_goto_second_case">
                Results
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                They say numbers don’t lie. So we captured some key metrics that
                made the entire GoTo and Workativ team proud of what we have
                accomplished so far and excited about some of the new
                capabilities that has got GoTo excited in making Workativ their
                all-in-one support automation platform in using bots, to
                connecting knowledge AI, and much more.   
              </p>

              <img src={results_mob} className="mb-4"></img>
            </div>
            <div id="section6">
              <h2 className="font-section-sub-header-small mb-4">
                Why{" "}
                <span className="ml-1 mr-2">
                  <img src={goto_logo} className="content_goto_logo">
                    {" "}
                  </img>
                </span>
                chose <span style={{ color: "#2468F6" }}>Workativ</span>
              </h2>
              <div className="why_goto_case_section">
                <div className="why_goto_case_left">
                  <div className="mobile_why_goto_manager">
                    <img
                      src={goto_manager_mobile}
                      className="goto_manager_case_mobile"
                    ></img>
                    <img src={quote} className="goto_quote_icon_case"></img>
                  </div>
                  <h2 className="font-section-sub-header-small-bold">
                    Gabriel Grecco
                  </h2>
                  <h4 className="font-section-normal-text-medium">
                    Global Manager - IT Service Desk
                  </h4>
                  <img src={goto_logo} className="why_goto_logo_mobile"></img>
                </div>
                <div className="why_goto_case_right">
                  <p className="font-section-normal-text-testimonials line-height-18">
                    After researching 5 different chatbot solutions, we decided
                    to go ahead with Workativ, for its’ easy integration with
                    systems that we use internally at GoTo. Workativ platform’s
                    advanced chatbot, app workflows, and deep Slack integration,
                    is a perfect fit for employee support automation.
                  </p>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    All the feedback that we passed to the team, was directly
                    turned into new functionalities in a matter of days! And we
                    felt supported throughout the entire onboarding process.
                  </p>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    The number of automation possibilities is immense! We can
                    empower our employees to self-service on tasks that were
                    currently handled by IT techs.
                  </p>
                </div>
              </div>
              <h3
                className="font-section-sub-header mb-3"
                style={{ color: "#193A5D" }}
              >
                Here’s why workativ stood out from other chatbot platforms
              </h3>
              <ol className="why_goto_order_list">
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>1</span>
                  Advanced chatbot platform with AI and App workflow automation
                  capabilities.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>2</span>
                  Ability to connect with multiple applications instantly. 
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>3</span>
                  Ability to deploy the chatbot in multiple channels like
                  self-help portal and Slack.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>4</span>
                  No-Code platform (no need to depend on developers to build the
                  chatbot).
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>5</span>
                  Transparent pricing.
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>6</span>
                  Ability to do a POC in weeks and test the chatbot before
                  making the decision.    
                </li>
                <li className="font-section-normal-text-testimonials mb-3">
                  <span>7</span>
                  Top-notch onboarding and customer success team.
                </li>
              </ol>
              <hr className="last_line_goto_case"></hr>
            </div>
          </div>
        </div>
        <h2 className="font-section-normal-text-case-study last_text_case_goto text-align-center">
          Learn more about GoTo’s success by speaking with our bot expert. Book
          demo&nbsp;<a href="/conversational-ai-platform/demo/">here.</a>
        </h2>
      </div>
    </section>
  );
}
